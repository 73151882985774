import React from "react"
import { withPrismicUnpublishedPreview, useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"
import { Link, graphql, PageProps, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"

const NotFoundQuery = graphql`
  query NotFoundQuery {
    prismicSettings {
      _previewable
      data {
        title
      }
    }
  }
`

const NotFound = (props: PageProps) => {
  const staticData = useStaticQuery(NotFoundQuery),
    { data: { prismicSettings } } = useMergePrismicPreviewData(staticData)

  return (
    <>
      <Helmet>
        {/* Title */}
        <title>404</title>
        <meta property="og:title" content={`404 | ${prismicSettings?.data?.title}`} />
        <meta name="twitter:title" content={`404 | ${prismicSettings?.data?.title}`} />
      </Helmet>
      <div className="w-full h-screen flex items-center justify-center">
        <h1 className="text-base">
          Page not found. <Link to="/">Go home</Link>.
        </h1>
      </div>
    </>
  )
}

export default withPrismicUnpublishedPreview(NotFound)
